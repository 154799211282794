import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  WrapperLight,
  WrapperDark,
  Content,
  Banner,
} from "../components/section-styling"
import { ButtonLink } from "../components/ButtonLink"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ConfirmationSuccessMsg = () => (
  <div>
    <h1>Excellent! You're done.</h1>
    <p>
      We'll be in touch soon. In the meantime, get a better idea of our work
      here and see what we're up to on our blog.
    </p>

    <ButtonLink to="/blog" className="dark long">
      READ OUR STORIES
    </ButtonLink>
  </div>
)

const ConfirmationFailMsg = () => (
  <div>
    <h1>Sorry, something went wrong.</h1>
    <p>
      We were unable to receive your information. Please email us directly:{" "}
      <b>info</b> [at] <b>sassafras</b> [dot] <b>coop</b> and we will get back
      to you soon.
    </p>

    <ButtonLink to="/" className="dark long">
      RETURN HOME
    </ButtonLink>
  </div>
)

const ConfirmationPage = ({ location }) => (
  <Layout>
    <Seo title="Confirmation" />

    <WrapperDark>
      <Content>
        {location.state?.formSuccess ? (
          <ConfirmationSuccessMsg />
        ) : (
          <ConfirmationFailMsg />
        )}
        <StaticImage
          src="../images/sassy-plant-26.png"
          className="large-image"
          height={1000}
          alt="Two plants beside each other."
        />
      </Content>
    </WrapperDark>

    <WrapperLight>
      <Banner>
        <h2>Wondering what we do?</h2>
        <ButtonLink to="/services" className="light long">
          VIEW OUR SERVICES
        </ButtonLink>
      </Banner>
    </WrapperLight>
  </Layout>
)

export default ConfirmationPage
